import React, { useState, KeyboardEvent } from 'react';
import sanitizeHtml from 'sanitize-html';
import isEmail from 'validator/lib/isEmail';
import styled from 'styled-components';

import useFocus from '../hooks/useFocus';
import { InnerFormProps, FormStatus, InputProps } from '../types';

const sanitize = (dirty: string) => sanitizeHtml(dirty, {
  allowedTags: [ 'a' ],
  allowedAttributes: {
    'a': [ 'href' ]
  },
});

const Wrapper = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const InputOuterWrapper = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  margin-top: 1.25rem;
  @media only screen and (min-width: 768px) {
    display: inline-flex;
    max-width: 100%;
    text-align: center;
  }
`;
" xs:flex xs:flex-wrap xs:items-center xs:mb-0 md:w-450 "

const InputInnerWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  @media only screen and (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
  }
  @media only screen and (min-width: 786px) {
    width: 450px;
  }
`;

const Label = styled.label`
  position: absolute;
  top: -1000px;
  left: -1000px;
`;

const Input = styled.input<InputProps>`
  width: 100%;
  height: 60px;
  color: #83838E;
  font-size: 1.125rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border-width: ${props => props.showError ? '1px' : '0'};
  border-color: #6B0505;
  border-style: solid;
  box-sizing: border-box;
  &::placeholder {
    color: #6C757D;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    outline: none;
  }
  @media only screen and (min-width: 576px) {
    display: inline-block;
    vertical-align: middle;
  }
`;

const ErrorMessage = styled.div`
  color: #FF0000;
  font-size: 14px;
  font-weight: bold;
`;

const Button = styled.button`
  height: 60px;
  background-color: #7EC682;
  border: none;
  color: white;
  font-size: 1.125rem;
  margin-left: 1.25rem;
  padding: 0 1.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    outline: none;
  }
`;

const ReponsesWrapper = styled.div`
  margin-top: 0.75rem;
`;

const InnerForm = ({ content, status, message, onValidated }: InnerFormProps) => {
  const [email, setEmail] = useState<string>('');
  const [inputError, setInputError] = useState<string>('');
  const [inputRef, setInputFocus] = useFocus();

  const showInputError = inputError.length > 0;

  const submit = () => {
    if (email.length === 0) {
      setInputFocus();
      return setInputError(content.fieldRequired);
    }
    if (!isEmail(email)) {
      setInputFocus();
      return setInputError(content.invalidInput);
    }
    onValidated({
      EMAIL: email,
    });
    setInputError('');
  };

  const handleKeypress = (e: KeyboardEvent): void => {
    // event.key first with graceful degradation to event.keyCode
    const key = e.key || e.keyCode;
    if (key === 'Enter') {
      submit();
    }
  };

  return (
    <Wrapper>
      <InputOuterWrapper>
        <InputInnerWrapper>
          <Label htmlFor="mce-EMAIL">Email adress</Label>
          <Input
            type="email"
            name="EMAIL"
            id="mce-EMAIL" 
            ref={inputRef}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={handleKeypress}
            placeholder={content.inputPlaceholder}
            showError={showInputError}
          />
          {showInputError ? <ErrorMessage>{inputError}</ErrorMessage> : null}
        </InputInnerWrapper>
        <Button onClick={submit}>
          {content.buttonMessage}
        </Button>
      </InputOuterWrapper>
      <ReponsesWrapper id="mce-responses">
        { 
          status === FormStatus.error && typeof message === 'string' && !showInputError ? 
          <span dangerouslySetInnerHTML={{ __html: sanitize(message) }}/> // nosemgrep
          : null
        }
        {
          status === FormStatus.success && !showInputError ?
          <span>{message}</span>
          : null
        }
      </ReponsesWrapper>
    </Wrapper>
  );
};

export default InnerForm;