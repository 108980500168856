import React from 'react';
import styled from 'styled-components';

import { Header } from './Header';
import { Footer } from './Footer';
import { LayoutProps } from '../types';

const Wrapper = styled.div`
  padding: 15px 0;
  @media only screen and (min-width: 768px) {
    padding: 34px 60px;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 15px;
  width: 100%;
`;

const Main = styled.main`
  padding: 1.25rem 0 2.5rem;
`;

export function Layout({ children, frontmatter }: LayoutProps): JSX.Element {
  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <Header logoLink={frontmatter.brandLogo} logoAlt={frontmatter.logoAlt}/>
          <Main>
            {children}
          </Main>
        </InnerWrapper>
      </Wrapper>
      <Footer content={frontmatter.footer} />
    </>
  );
}
