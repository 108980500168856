import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Background from '../components/Background';
import { TextBody }  from '../components/TextBody';
import { SignupForm } from '../components/SignupForm';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { IndexProps } from '../types';
import GlobalStyle from '../styles/globalStyles';

const H1 = styled.h1`
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 0 1.25rem;
  @media only screen and (min-width: 768px) {
    font-size: 70px;
  }
`;

function IndexPage({ data }: IndexProps): JSX.Element {
  const frontmatter = data.markdownRemark.frontmatter;
  return (
    <>
      <GlobalStyle />
      <Background>
        <Layout frontmatter={frontmatter}>
          <Seo title={frontmatter.siteTitle} />
            <H1>{frontmatter.header}</H1>
            <TextBody paragraphs={frontmatter.paragraphs}/>
            <SignupForm content={frontmatter.signupForm}/>
        </Layout>
      </Background>
    </>
  );
}

export const pageQuery = graphql`
  query Index{
    markdownRemark(fields: {slug: {eq: "/IndexPage"}}) {
      frontmatter {
        brandLogo
        logoAlt
        siteTitle
        header
        paragraphs {
          content
          bold
        }
        signupForm {
          formLink
          inputPlaceholder
          buttonMessage
          fieldRequired
          invalidInput
        }
        footer {
          copyright
          links {
            href
            text
          }
          social {
            link
            icon
            alt
          }
        }
      }
    }
  }
`;

export default IndexPage;
