import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Image from './Image';
import { FooterProps } from '../types';

const Wrapper = styled.div`
  position: relative;
  right: 0;
  left: 0;
  bottom: 1.25rem;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  @media only screen and (min-width: 768px) {
    position: absolute;
    padding: 0 75px;
  }
`;

const LegalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Copyright = styled.span`
  margin-right: 0.75rem;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const LegalLink = styled(Link)`
  margin-right: 0.75rem;
  white-space: nowrap;
`;

const SocialList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

const SocialLink = styled.li`
  display: inline-block;
  margin: 0.75rem;
`;

const SocialIcon = styled(Image)`
  width: 2rem;
`;


export function Footer({ content }: FooterProps): JSX.Element {
  return (
    <Wrapper>
      <LegalWrapper>
        <Copyright>{`${new Date().getFullYear()} ${content.copyright}`}</Copyright>
        <LinksWrapper>
          {content.links.map((link, index) => {
            return (
              <LegalLink key={index} to={link.href}>{link.text}</LegalLink>
            )
          })}
        </LinksWrapper>
      </LegalWrapper>
      <SocialList>
        {content.social.map(el => {
          return (
            <SocialLink key={el.link}>
              <a href={el.link}>
              <SocialIcon fileName={el.icon} alt={el.alt} />
              </a>
            </SocialLink>
          )
        })}
      </SocialList>
    </Wrapper>
  );
}
