import React from 'react';
import styled from 'styled-components';

import { TextBodyProps } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const TextWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  font-size: 15px;
  @media only screen and (min-width: 576px) {
    max-width: 83.333333%;
  }
  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
`;

const P = styled.p`
  margin: 0 0 1rem;
`;

export function TextBody({ paragraphs }: TextBodyProps): JSX.Element {
  return (
    <Wrapper>
      <TextWrapper>
        {paragraphs.map((p, index) => {
          return (
            <P key={index}>
              {p.content}
              <b>{p.bold ?? ''}</b>
            </P>
          );
        })}
      </TextWrapper>
    </Wrapper>
  );
}
