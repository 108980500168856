import { EmailFormFields } from 'react-mailchimp-subscribe';

// Pages props
export interface IndexProps {
  data: {
    markdownRemark: MarkdownRemark
  }
}

export interface LegalProps {
  data: {
    markdownRemark: IMarkdownRemark 
  }
}

// Components props
export interface LayoutProps {
  children: React.ReactNode;
  frontmatter: {
    brandLogo: string,
    logoAlt: string,
    footer: Footer,
  }
}

export interface LayoutSecondaryProps {
  children: React.ReactNode;
  brandLogo: string,
  logoAlt: string,
}

export interface HeaderProps {
  logoLink: string,
  logoAlt: string,
}

export interface BackgroundProps {
  children: React.ReactNode
}

export interface FooterProps {
  content: Footer
}

export interface ImageProps {
  fileName: string,
  alt: string,
  className?: string,
}

export interface TextBodyProps {
  paragraphs: Paragraph[],
}

export interface SignupFormProps {
  content: SignupForm,
}

export interface InnerFormProps {
  content: SignupForm,
  status: keyof typeof FormStatus | null,
  message: string | Error | null,
  onValidated: (formData: EmailFormFields) => void,
};

export interface SeoProps {
  description?: string;
  lang?: HtmlProps;
  meta?: MetaProps[];
  title: string;
}

export interface LegalTextProps {
  content: Legalcontent[]
}

export interface InputProps {
  readonly showError: boolean;
}

// Data
export interface MarkdownRemark {
  frontmatter: Frontmatter
}

export interface Frontmatter {
    brandLogo: string,
    logoAlt: string,
    siteTitle: string,
    header: string, 
    paragraphs: Paragraph[],
    signupForm: SignupForm,
    footer: Footer
}

export interface Paragraph {
  content: string, 
  bold?: string
}

export interface SignupForm {
  formLink: string,
  inputPlaceholder: string,
  buttonMessage: string,
  fieldRequired: string,
  invalidInput: string,
}

interface Footer {
  copyright: string,
  links: FooterLink[],
  social: Social[]
}

interface FooterLink {
  href: string,
  text: string
}

interface Social  {
  link: string,
  icon: string,
  alt: string
}

export const FormStatus = {
  success: "success",
  error: "error",
  sending: "sending"
} as const;

type MetaProps = JSX.IntrinsicElements['meta'];

type HtmlProps = JSX.IntrinsicElements['html'] & {
  [key: string]: string;
};

export interface IMarkdownRemark {
  frontmatter: FrontmatterLegal,
  html: string,
}

interface FrontmatterLegal {
  brandLogo: string,
  logoAlt: string
}

interface Legalcontent {
  header: string
  description: string
}
