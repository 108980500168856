import React from 'react';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';

import InnerForm from './InnerForm';
import { SignupFormProps } from '../types';

// TODO: move url variable to .env
const url = "https://amarosociety.us3.list-manage.com/subscribe/post?u=d27b54a1e5391c2fb6b67f43d&id=e3ad57001e";

export function SignupForm({ content }: SignupFormProps): JSX.Element {
  return (
    <div id="mc_embed_signup">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <InnerForm
            content={content}
            status={status}
            message={message}
            onValidated={(formData: EmailFormFields) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
}
